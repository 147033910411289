import LdcApi from '../../LdcApis';

class Patients {
  getExistPatients = (terms, callback) => {
    LdcApi({
      method: 'GET',
      url: `Patients/SearchPatient?searchData=${terms}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getExistPatientsByEmailOrPhn = (terms, callback) => {
    LdcApi({
      method: 'GET',
      url: `Patients/PatientSearchByMOrE?SearchValue=${terms}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  // https://api-ldc.goldenbuzz.in/api/Patients/SearchPatientByPI?PatientId=2

  getExistPatientsID = (terms, cancelTokens, callback) => {
    LdcApi({
      method: 'GET',
      url: `Patients/SearchPatientByPI?PatientId=${terms}`,
      cancelToken: cancelTokens,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getAllPatients = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'Patients/GetPatients',
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        // callback({ status: error });
      });
  };

  getParientDetailsById = (id, callback) => {
    LdcApi({
      method: 'GET',
      url: `Patients/GetPatientsById?id=${id}`,
    })
      .then((response) => {
        callback({ status: 'success', data: response?.data });
      })
      .catch((error) => {
        // callback({ status: error });
      });
  };

  addPatients = (patientsData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'Patients/AddPatients',
      data: patientsData,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  addJobsPatients = (patientsData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'JobList/UpsertJobList',
      data: patientsData,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getJobListForPatient = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'JobList/GetJobList?category',
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getPatientsDataByPatientId = (patientId, callback) => {
    LdcApi({
      method: 'GET',
      url: `Appointment/GetAppointmentByPatientId?patientId=${patientId}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getPatientsJobById = (patientId, callback) => {
    LdcApi({
      method: 'GET',
      url: `JobList/GetjobsListByPatientId?patientId=${patientId}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getTreatmentNotesByPatientID = (patientId, callback) => {
    LdcApi({
      method: 'GET',
      url: `TreatmentNotes/GetTreatmentNotesByPatientID?patientId=${patientId}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };

  getLeadDetailsByNumber = (number, callback) => {
    LdcApi({
      method: 'GET',
      url: `LeadManagement/LeadSearch?searchData=${number}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };

  getDoctorListForAddTaskList = (callback) => {
    LdcApi({
      method: 'GET',
      url: `Doctor/GetDoctorList`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };

  getCategoryListStatus = (callback) => {
    LdcApi({
      method: 'GET',
      url: `JobList/GetCategoryList`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };

  getRoleListStatus = (callback) => {
    LdcApi({
      method: 'GET',
      url: `JobList/GetRoleDoctorList`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };

  getJobListForPatientByCategory = (category, callback) => {
    LdcApi({
      method: 'GET',
      url: `JobList/GetJobList?${category !== 'All' ? `category=${category}` : `category`}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };

  getMailPdfByCreateLetter = (content, callback) => {
    LdcApi({
      method: 'POST',
      url: `TreatmentNotes/GetMailPdf`,
      data: content,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  deletePatientTask = (taskId, callback) => {
    LdcApi({
      method: 'GET',
      url: `JobList/DeleteJoblist?Id=${taskId}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  sentMailForAppoinmentReview = (content, callback) => {
    LdcApi({
      method: 'POST',
      url: `Appointment/SentApptReviewMail`,
      data: content,
    })
      .then((response) => {
        if (response?.data) {
          callback({
            status: 'success',
            data: response?.data,
          });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
}

export default new Patients();
